

































import {Component, Vue} from 'vue-property-decorator';
import {TeamInterface} from '@/shared/interfaces/store/homepage/team.interface';
import {HomepageStore} from '@/store/modules/homepage';

@Component
export default class HomeTeam extends Vue {

  get team(): TeamInterface {
    return HomepageStore.team;
  }

}
